<template>
  <a :href="card.url" class="item">
    <div
      class="content"
      :style="{
        background: `linear-gradient( rgba(50, 50, 50, 0.4), rgba(0, 0, 0, 1) ), url(${card.image})`,
      }"
    >
      <div class="aspect-ratio"></div>
      <div class="overlay">
        <div class="title-group">
          <b-badge class="overlay-category">{{
            translate(card.category)
          }}</b-badge>
          <h2 class="overlay-title">{{ translate(card.title) }}</h2>
          <h4 class="overlay-description">
            {{ translate(card.description) }}
          </h4>
        </div>
        <div class="overlay-block">
          <div class="time-date">
            <font-awesome-icon class="time-icon" icon="clock" />
            <span class="date">{{ card.date | moment("mm-DD-YYYY") }} </span>
            <span class="time">{{ card.date | moment("hh:mm a") }}</span>
          </div>
          <div class="speaker"></div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import moment from "moment";
import { translateSetting } from "@/utils/translated-setting.util";

export default {
  props: ["card"],
  methods: {
    // dateYearSameElse() {
    //   if (this.years() === now.years()) {
    //     return 'MMM D'
    //   } else {
    //     return 'M/D/YY';
    //   }
    // },
    dateFromNow(date) {
      return moment(date).fromNow();
    },
    translate(value) {
      return translateSetting(value, this.locale);
    },
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.vue-horizontal {
  // box-shadow: -32px 0 30px -25px black, 32px 0 35px -25px black;
}

.content {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  z-index: -1;
}

.aspect-ratio {
  padding-top: 60%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000010;
  justify-content: center;
}

.title-group {
  z-index: 1;
  padding: 15px;
  position: absolute;
  top: 55px;
  height: 75%;
  transition: top 0.5s;
  .overlay-title {
    margin-top: 5px;
    font-size: 1.4em !important;
  }

  .overlay-description {
    margin-top: 5px;
    font-size: 1.2em;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;
  }
}

.overlay-block {
  // z-index: 1;
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 25%;
  width: 100%;
  transition: height 0.5s;
}

.time-date {
    position: absolute;
    color: $text-color-title !important;
    bottom: 12px;
    // line-height: 20px;
    .date {
      margin-left: 10px;
      font-size: 16px;
    }
    .time {
      font-size: 14px;
    }
  }

.item:hover {
  .title-group {
    top: 0px;
    .overlay-description {
      visibility: visible;
      opacity: 1;
    }
  }
  .overlay-block {
    height: 100%;
  }
}

.overlay > * {
  color: white;
}
</style>

<style scoped>
.horizontal {
  --count: 1;
  --gap: 16px;
  --margin: 24px;
}

@media (min-width: 640px) {
  .horizontal {
    --count: 2;
  }
}

@media (min-width: 768px) {
  .horizontal {
    --count: 3;
    --margin: 0;
  }
}

@media (min-width: 1024px) {
  .horizontal {
    --count: 4;
  }
}

@media (min-width: 1280px) {
  .horizontal {
    --gap: 24px;
    --count: 6;
  }
}
</style>
<!-- Responsive Breakpoints -->
<style scoped>
.horizontal {
  --count: 1;
  --gap: 16px;
  --margin: 24px;
}

@media (min-width: 640px) {
  .horizontal {
    --count: 2;
  }
}

@media (min-width: 768px) {
  .horizontal {
    --count: 2;
    --margin: 0;
  }
}

@media (min-width: 1024px) {
  .horizontal {
    --count: 3;
  }
}

@media (min-width: 1280px) {
  .horizontal {
    --gap: 24px;
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 767.98px) {
  .item {
    width: 250px;
    padding-left: var(--margin);
  }
  .title-group {
    // position: absolute;
    top: 0px;
    height: 75%;
    .overlay-title {
      font-size: 17px !important;
    }
  }
  .overlay-block {
    .time-date {
      bottom: 7px;
    }
  }

  .item:hover {
    .title-group {
      top: 0px;
      .overlay-description {
        visibility: hidden;
        opacity: 0;
      }
    }
    .overlay-block {
      height: 100%;
    }
  }

  .horizontal {
    margin: 0 calc(var(--margin) * -1);
  }

  .horizontal >>> .v-hl-container {
    scroll-padding: 0 calc(var(--margin) - (var(--gap) / 2));
  }

  .horizontal >>> .v-hl-btn {
    display: none;
  }
}

@media (min-width: 768px) {
  .item {
    width: 300px;
    margin-right: var(--gap);
  }
}
</style>
